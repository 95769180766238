import axios, { AxiosError } from 'axios'

import { api } from '../ticketingAxiosInstance'
import { RawTicketAttachment } from './attachments.types'

const getAttachmentsUrl = (ticketId: string) =>
  `/v1/tickets/${ticketId}/attachments`

export const attachmentsService = {
  async uploadAttachments(
    formData: FormData,
    ticketId: string,
    authToken: string,
  ): Promise<{ error?: string; success: boolean }> {
    try {
      await api.post(getAttachmentsUrl(ticketId), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + authToken,
        },
      })

      return { success: true }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 413) {
          return { success: false, error: 'Uploaded file is too large' }
        } else {
          return { success: false, error: 'Failed to upload attachments' }
        }
      }

      return {
        success: false,
        error: 'Unknown error while uploading attachments',
      }
    }
  },
  async uploadAttachmentsToS3(
    formData: FormData,
    ticketId: string,
    authToken: string,
  ): Promise<{ error?: string; success: boolean }> {
    try {
      // Get files from FormData
      const files = formData.getAll('files') as File[]
      if (!files.length) {
        return { success: false, error: 'No files provided' }
      }

      // Upload each file
      const uploadPromises = files.map(async file => {
        // Get presigned URL for upload
        const presignedResponse = await api.get(
          `${getAttachmentsUrl(ticketId)}/presigned-post-policy`,
          {
            params: {
              attachmentName: file.name,
              contentType: file.type,
            },
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          },
        )
        const { url, fields } = presignedResponse.data.raw.data.attributes
        const parsedFields = JSON.parse(fields)

        // Create new FormData for S3 upload
        const s3FormData = new FormData()

        // Add fields from presigned URL
        Object.entries(parsedFields).forEach(([key, value]) => {
          s3FormData.append(key, value as string)
        })

        // Add file as the last field
        s3FormData.append('file', file)

        // Upload to S3
        const uploadResponse = await axios.post(url, s3FormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })

        if (uploadResponse.status !== 204) {
          throw new Error(`Upload failed with status ${uploadResponse.status}`)
        }
      })

      // Wait for all uploads to complete
      await Promise.all(uploadPromises)
      return { success: true }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          // This is workaround for the CORS issue with the ticketing S3 bucket
          return { success: true }
        }

        if (error.response?.status === 413) {
          return { success: false, error: 'Uploaded file is too large' }
        }

        return {
          success: false,
          error: `Failed to upload attachments ${(error as Error).message}`,
        }
      }

      return {
        success: false,
        error: `Unknown error while uploading attachments: ${
          (error as Error).message
        }`,
      }
    }
  },
  async getAttachments(
    ticketId: string,
    authToken: string,
  ): Promise<{
    data: RawTicketAttachment[]
    error?: string
    success: boolean
  }> {
    try {
      const response = await api.get(getAttachmentsUrl(ticketId), {
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
      })

      return { success: true, data: response.data.raw.data }
    } catch (error) {
      return {
        data: [],
        success: false,
        error:
          'Failed to fetch attachments. Please try reload the application.',
      }
    }
  },
  async deleteAttachment(
    ticketId: string,
    filename: string,
    authToken: string,
  ): Promise<{ error?: string; success: boolean }> {
    try {
      await api.delete(
        `${getAttachmentsUrl(ticketId)}?attachmentNames=${filename}`,
        {
          headers: {
            Authorization: 'Bearer ' + authToken,
          },
        },
      )

      return { success: true }
    } catch (error) {
      return {
        success: false,
        error: 'Failed to delete attachment. Please try again.',
      }
    }
  },
}
