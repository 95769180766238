import styled from '@emotion/styled'
import { useDecision } from '@optimizely/react-sdk'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { Loader, LoaderAlignment, Button, Alert } from 'packages/common'
import { Features } from 'packages/optimizely'
import { colors, text } from 'packages/styles'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { attachmentsService } from 'app/fieldapp/store/attachments/attachments.service'
import { RawTicketAttachment } from 'app/fieldapp/store/attachments/attachments.types'
import { getAuthToken } from 'app/fieldapp/store/auth/selectors'

import { FileUploaderItem } from '../../schedule/components/common/CreateTicket/FileUploader/FileUploaderItem'
import { VisitTicketSectionTitle } from '../../schedule/components/TaskSharedStyles/VisitTicket.styles'
import {
  ACCEPTED_FILE_TYPES,
  MAX_TOTAL_SIZE_MB,
  MAX_FILE_SIZE_WITH_S3_MB,
  MB_IN_BYTES,
} from './constants'

const St = {
  AddButton: styled(Button)`
    font-size: 25px;
  `,
  FileInput: styled.input`
    display: none;
  `,
  LoaderWrapper: styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: 100px;
  `,
  NoAttachments: styled.div`
    ${text.bodyRegularSmall}
    font-style: italic;
    margin-top: 24px;
  `,
  SectionTitle: styled(VisitTicketSectionTitle)`
    display: flex;
    flex-direction: row;
    grid-gap: 8px;
  `,
  TitleContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  `,
}

const useTranslations = () => {
  const { t } = useI18n()

  return {
    attachments: t(Slugs.attachments),
    noAttachments: t(Slugs.noAttachments),
  }
}

export type TicketAttachmentsProps = {
  ticketId: string
}

export const TicketAttachments: React.FC<TicketAttachmentsProps> = ({
  ticketId,
}) => {
  const { attachments, noAttachments } = useTranslations()
  const [loading, setLoading] = useState(true)
  const [ticketAttachments, setTicketAttachments] = useState(
    [] as RawTicketAttachment[],
  )
  const [errors, setErrors] = useState<string[]>([])
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const authToken = useSelector(getAuthToken) as string
  const [decision] = useDecision(Features.NEW_TICKET_ATTACHMENTS_ENDPOINT)
  const isNewAttachmentsEndpoint = decision.enabled

  const fetchTicketAttachments = useCallback(async () => {
    setLoading(true)
    const attachments = await attachmentsService.getAttachments(
      ticketId,
      authToken,
    )
    if (attachments.error !== undefined) {
      setErrors([attachments.error])
    } else {
      setErrors([])
      setTicketAttachments(attachments.data)
    }

    setLoading(false)
  }, [ticketId, authToken])

  const deleteTicketAttachment = async (attachment: RawTicketAttachment) => {
    const originalTicketAttachments = [...ticketAttachments]
    const attachmentsWithoutDeleted = ticketAttachments.filter(
      a => a.attributes.filename !== attachment.attributes.filename,
    )
    setErrors([])
    setTicketAttachments(attachmentsWithoutDeleted) // optimistic update
    const deleteResponse = await attachmentsService.deleteAttachment(
      ticketId,
      getAttachmentName(attachment),
      authToken,
    )
    if (deleteResponse.error !== undefined) {
      setErrors([deleteResponse.error])
      setTicketAttachments(originalTicketAttachments) // revert optimistic update
    }
  }

  const uploadAttachments = async (files: FileList) => {
    const formData = new FormData()

    // Append all files under the 'files' key
    Array.from(files).forEach(file => {
      formData.append('files', file)
    })

    let response: { error?: string; success: boolean }
    if (isNewAttachmentsEndpoint) {
      response = await attachmentsService.uploadAttachmentsToS3(
        formData,
        ticketId,
        authToken,
      )
    } else {
      response = await attachmentsService.uploadAttachments(
        formData,
        ticketId,
        authToken,
      )
    }

    if (response.error) {
      setErrors([response.error])
    } else {
      await fetchTicketAttachments()
    }
  }

  const handleAddButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click() // Trigger file input dialog
    }
  }

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files
    if (files && files.length > 0) {
      if (isNewAttachmentsEndpoint) {
        if (
          Array.from(files).some(
            file => file.size > MAX_FILE_SIZE_WITH_S3_MB * MB_IN_BYTES,
          )
        ) {
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }

          setErrors([
            `File size limit exceeded. The maximum size for one file is ${MAX_FILE_SIZE_WITH_S3_MB}MB`,
          ])

          return
        }
      } else {
        const filesSize =
          Array.from(files).reduce((acc, file) => acc + file.size, 0) /
          MB_IN_BYTES

        if (filesSize > MAX_TOTAL_SIZE_MB) {
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }

          setErrors([
            `File size ${filesSize.toFixed(
              1,
            )}MB limit exceeded. The maximum file size is ${MAX_TOTAL_SIZE_MB}MB`,
          ])

          return
        }
      }

      setLoading(true)
      await uploadAttachments(files)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTicketAttachments()
  }, [fetchTicketAttachments, ticketId])

  const getAttachmentName = (attachment: RawTicketAttachment) =>
    attachment.attributes.filename.slice(ticketId.toString().length + 1)

  return (
    <>
      <St.TitleContainer>
        <St.SectionTitle>{attachments}</St.SectionTitle>

        <St.AddButton
          buttonSize="small"
          onClick={handleAddButtonClick}
          data-testid="add-attachment-button"
        >
          +
        </St.AddButton>

        <St.FileInput
          type="file"
          ref={fileInputRef}
          multiple={true}
          onChange={handleFileSelect}
          accept={ACCEPTED_FILE_TYPES}
          data-testid="add-attachment-input"
        />
      </St.TitleContainer>

      {errors.map(error => (
        <Alert alertType="danger">{error}</Alert>
      ))}

      {loading ? (
        <St.LoaderWrapper>
          <Loader
            alignment={LoaderAlignment.center}
            color={colors.dusk}
            size={12}
          />
        </St.LoaderWrapper>
      ) : ticketAttachments.length > 0 ? (
        <div>
          {ticketAttachments.map(attachment => (
            <FileUploaderItem
              key={attachment.attributes.filename}
              file={
                {
                  name: getAttachmentName(attachment),
                } as File
              }
              onRemove={() => deleteTicketAttachment(attachment)}
              onClick={() => window.open(attachment.attributes.url)}
            />
          ))}
        </div>
      ) : (
        <St.NoAttachments>{noAttachments}</St.NoAttachments>
      )}
    </>
  )
}
