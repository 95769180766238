import { useDecision } from '@optimizely/react-sdk'
import { useEffect, useCallback, useRef } from 'react'

import { Features } from 'packages/optimizely'
import {
  createDateObject,
  DateFormat,
  formatLocalized,
  formatInTimeZone,
} from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { useAppDispatch } from 'app/fieldapp/store/hooks'
import { setBanner } from 'app/fieldapp/store/ui/actions'
import { TimerErrorData } from 'app/fieldapp/store/utils'
import { fetchTimersData } from 'app/fieldapp/store/utils/timers/actions/fetchTimersData'

const MIN_RETRY_DELAY = 3000 // 3 seconds
const MAX_RETRY_DELAY = 300000 // 5 minutes
const LAST_TIMERS_CHECK_KEY = 'timersDataCheck'
const TIMERS_DATA_ERRORS_KEY = 'timersDataErrors'
const THIRTY_DAYS_MS = 30 * 24 * 60 * 60 * 1000
// "WTK-200046",  # Cannot have overlapping spans using same Activity.
// "WTK-116000",  # Exceeding max limit of direct activities.
const NOTIFIABLE_ERROR_CODES = ['WTK-200046', 'WTK-116000']

type ErrorId = {
  id: string
  timestamp: number
}

const currentTimestampInMillis = (): number => createDateObject().getTime()

// Retrieves the last successful timers check timestamp.
const getLastTimersCheck = (): string | null =>
  localStorage.getItem(LAST_TIMERS_CHECK_KEY)

// Updates the last successful timers check timestamp.
const setLastTimersCheck = (timestamp: string): void =>
  localStorage.setItem(LAST_TIMERS_CHECK_KEY, timestamp)

// Gets the stored error IDs from localStorage.
const getStoredErrorIDs = (): ErrorId[] => {
  try {
    const stored = localStorage.getItem(TIMERS_DATA_ERRORS_KEY)
    return stored ? JSON.parse(stored) : []
  } catch (error) {
    return []
  }
}

// Saves the error IDs array to localStorage.
const saveStoredErrorIDs = (errorIDs: ErrorId[]): void =>
  localStorage.setItem(TIMERS_DATA_ERRORS_KEY, JSON.stringify(errorIDs))

// Filters out errors that have already been stored.
const filterNewErrors = (
  timerErrors: TimerErrorData[],
  storedErrorIDs: ErrorId[],
): TimerErrorData[] => {
  const storedErrorIDsSet = storedErrorIDs.map(entry => entry.id)
  return timerErrors.filter(
    error => error.id && !storedErrorIDsSet.includes(error.id),
  )
}

const isErrorNotifiable = (error: TimerErrorData): boolean => {
  const msg = error.error_reason
  const codes = NOTIFIABLE_ERROR_CODES
  return !!msg && codes.some(code => msg.includes(code))
}

export const useCheckFailedTimers = (
  runAutomatically = false,
): { checkFailedTimers: () => void } => {
  const dispatch = useAppDispatch()
  const { t } = useI18n()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [decision] = useDecision(Features.FAILED_TIMERS_CHECK)
  const enabled = decision.enabled

  const processTimerErrors = useCallback(
    (timerErrors: TimerErrorData[]) => {
      const storedErrorIDs = getStoredErrorIDs()
      const newErrors = filterNewErrors(timerErrors, storedErrorIDs)

      if (newErrors.length > 0) {
        newErrors.forEach(error => {
          if (isErrorNotifiable(error)) {
            const started_at = formatLocalized(
              error.started_at,
              DateFormat.Full,
            )
            const stopped_at = formatLocalized(
              error.stopped_at,
              DateFormat.Full,
            )
            dispatch(
              setBanner({
                message: t(Slugs.timerDataError, { started_at, stopped_at }),
              }),
            )
          }
        })

        // filter out errors that are older than 30 days
        const thirtyDaysAgo = currentTimestampInMillis() - THIRTY_DAYS_MS
        const currentErrorIds = storedErrorIDs.filter(
          entry => entry.timestamp > thirtyDaysAgo,
        )
        // add new errors to the list
        const newErrorIds: ErrorId[] = newErrors.map(
          (error: TimerErrorData) => {
            return { id: error.id, timestamp: currentTimestampInMillis() }
          },
        )
        // save the updated list of error IDs
        saveStoredErrorIDs([...currentErrorIds, ...newErrorIds])
      }
    },
    [dispatch, t],
  )

  const checkFailedTimers = useCallback(
    async (attempt = 1) => {
      if (!enabled) {
        return
      }

      const lastCheck = getLastTimersCheck()
      const timersData = await fetchTimersData(lastCheck)

      if (timersData.timer_errors?.length > 0) {
        processTimerErrors(timersData.timer_errors)
      }

      if (timersData.cnt_pending === 0) {
        const now = formatInTimeZone(
          createDateObject(),
          'UTC',
          DateFormat.IsoDateAndTime,
        )
        setLastTimersCheck(now)
      } else {
        const nextDelay = Math.min(
          MIN_RETRY_DELAY * Math.pow(2, attempt - 1),
          MAX_RETRY_DELAY,
        )

        timeoutRef.current = setTimeout(() => {
          checkFailedTimers(attempt + 1)
        }, nextDelay)
      }
    },
    [enabled, processTimerErrors],
  )

  useEffect(() => {
    if (runAutomatically) {
      checkFailedTimers()
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [checkFailedTimers, runAutomatically])

  return { checkFailedTimers }
}
