import { TimersData } from 'packages/grimoire/src/utils/timers.types'

import { api } from 'app/fieldapp/store/axiosInstance'
const URL = '/timers/timers-data'

/**
 * Fetches timers data from the API
 * @param modifiedAt Optional UTC timestamp to filter results
 */
export const fetchTimersData = async (
  modifiedAt?: string | null,
): Promise<TimersData> => {
  const params = modifiedAt ? { modified_at: modifiedAt } : {}
  const response = await api.get(URL, { params })

  // Following pattern from other services for safe fallback
  return { ...response?.data?.raw?.data }
}
