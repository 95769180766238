import { xor } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { apiFilterExcludeCleans } from 'packages/grimoire'
import {
  createDateObject,
  subDays,
  DateFormat,
  format,
} from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
} from 'packages/utils/store/jsonapi.types'

import { getDateRange } from '../../cleans/actions/fetchCleans'
import { HousekeeperApiFields } from '../../housekeepers'
import { LockboxApiFields } from '../../lockboxes'
import { ReservationApiFields } from '../../reservations'
import { SmartLockApiFields } from '../../smartlocks'
import { NormalizedTasksApiResponse, TasksResponse } from '../../tasks'
import { tasksService } from '../../tasks/tasks.service'
import { emptyNormalizedTasksData } from '../../tasks/tasks.utils'
import { TicketApiFields } from '../../tickets'
import { UnitApiFields } from '../../units'
import { UserApiFields } from '../../users'
import { VisitApiFields, VisitsActionTypes } from '../visits.types'

export const fetchVisitsAction = createAsyncAction(
  VisitsActionTypes.FETCH_VISITS,
  VisitsActionTypes.FETCH_VISITS_SUCCESS,
  VisitsActionTypes.FETCH_VISITS_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

// omit inspectionChecklist from the "all visits" request for performance reasons
const VisitFields = xor(['inspection_checklist'], VisitApiFields)

const DaysBackForActiveVisits = 7

export const getParams = (
  userId: string,
  dateRange: string | { range: string[] },
): RequestOptions => {
  return {
    fields: {
      assignment: [],
      housekeeper: HousekeeperApiFields,
      lock_box: LockboxApiFields,
      reservation: ReservationApiFields,
      smart_lock: SmartLockApiFields,
      task: VisitFields,
      ticket: TicketApiFields,
      unit: UnitApiFields,
      user: UserApiFields,
    },
    filter: {
      'active_assignments.housekeeper.user': userId,
      effective_date: dateRange,
      is_active: true,
      ...apiFilterExcludeCleans,
    },
    include: [
      'active_assignments',
      'active_assignments.housekeeper',
      'active_assignments.housekeeper.user',
      'assigned_housekeepers.user',
      'reservation',
      'smart_locks',
      'tickets',
      'unit',
      'unit.current_reservation',
      'unit.lock_box',
      'unit.next_reservation',
      'unit.previous_reservation',
    ],
    page: { size: 100 },
    sort: ['effective_date'],
  }
}

export const getActiveVisitsParams = (
  userId: string,
  dateRange: string | { range: string[] },
): RequestOptions => {
  return {
    fields: {
      assignment: [],
      housekeeper: HousekeeperApiFields,
      lock_box: LockboxApiFields,
      reservation: ReservationApiFields,
      smart_lock: SmartLockApiFields,
      task: VisitFields,
      ticket: TicketApiFields,
      unit: UnitApiFields,
      user: UserApiFields,
    },
    filter: {
      'active_assignments.housekeeper.user': userId,
      all_active_visits: true,
      effective_date: dateRange,
      is_active: true,
      ...apiFilterExcludeCleans,
    },
    include: [
      'active_assignments',
      'active_assignments.housekeeper',
      'active_assignments.housekeeper.user',
      'assigned_housekeepers.user',
      'reservation',
      'smart_locks',
      'tickets',
      'unit',
      'unit.current_reservation',
      'unit.lock_box',
      'unit.next_reservation',
      'unit.previous_reservation',
    ],
    page: { size: 100 },
    sort: ['effective_date'],
  }
}

export const getActiveVisits =
  (userId: string, dateRange: string | { range: string[] }) =>
  async dispatch => {
    try {
      const activeVisitsParams = getActiveVisitsParams(userId, dateRange)
      const activeVisitsRequest = tasksService.fetchTasks.bind(
        null,
        activeVisitsParams,
      )
      const result = await dispatch(
        fetchVisitsAction.request({ request: activeVisitsRequest }),
      )

      return result
    } catch (error) {
      dispatch(fetchVisitsAction.failure(error))
      throw error
    }
  }

export const fetchVisits =
  (
    userId: string,
    startDate: string,
    endDate?: string,
    fetchActiveVisits?: boolean,
  ) =>
  async dispatch => {
    try {
      const today = createDateObject()
      const dateRange = getDateRange(startDate, endDate)
      const activeVisitsDateRange = getDateRange(
        format(subDays(today, DaysBackForActiveVisits), DateFormat.ApiUtcShort),
        format(today, DateFormat.ApiUtcShort),
      )

      const dateRangeParams = getParams(userId, dateRange)
      const dateRangeRequest = tasksService.fetchTasks.bind(
        null,
        dateRangeParams,
      )

      if (fetchActiveVisits) {
        const [dateRangeResult, activeVisitsResult] = await Promise.all([
          dispatch(fetchVisitsAction.request({ request: dateRangeRequest })),
          getActiveVisits(userId, activeVisitsDateRange)(dispatch),
        ])

        // Start with empty normalized data structure to ensure all required properties exist
        const mergedNormalized: TasksResponse = {
          ...emptyNormalizedTasksData,
        }

        // Merge each entity type from both results
        Object.keys(mergedNormalized).forEach(key => {
          const entityKey = key as keyof TasksResponse
          mergedNormalized[entityKey] = {
            ...dateRangeResult.normalized[entityKey],
            ...activeVisitsResult.normalized[entityKey],
          }
        })

        const mergedResult: NormalizedTasksApiResponse = {
          normalized: mergedNormalized,
          raw: {
            ...dateRangeResult.raw,
            ...activeVisitsResult.raw,
            data: [...dateRangeResult.raw.data, ...activeVisitsResult.raw.data],
          },
        }

        dispatch(fetchVisitsAction.success(mergedResult))
        return mergedResult.normalized
      } else {
        const result = await dispatch(
          fetchVisitsAction.request({ request: dateRangeRequest }),
        )
        dispatch(fetchVisitsAction.success(result))
        return result.normalized
      }
    } catch (error) {
      dispatch(fetchVisitsAction.failure(error))
      throw error
    }
  }
