enum Slugs {
  access = 'access',
  addVisit = 'addVisit',
  addVisitInspectionSubtitle = 'addVisitInspectionSubtitle',
  addVisitSuccessMessage = 'addVisitSuccessMessage',
  additionalHomeInfo = 'additionalHomeInfo',
  address = 'address',
  alarmCode = 'alarmCode',
  all = 'all',
  anErrorOccurred = 'anErrorOccurred',
  anErrorOccurredLong = 'anErrorOccurredLong',
  areYouSure = 'areYouSure',
  assignedTo = 'assignedTo',
  authErrorBody = 'authErrorBody',
  backup = 'backup',
  badgeB2b = 'badgeB2b',
  badgeEarly = 'badgeEarly',
  badgeLate = 'badgeLate',
  cancel = 'cancel',
  cannotLoadDate = 'cannotLoadDate',
  cleanNotesTitle = 'cleanNotesTitle',
  cleanType = 'cleanType',
  comments = 'comments',
  completed = 'completed',
  confirm = 'confirm',
  confirmed = 'confirmed',
  copied = 'copied',
  copy = 'copy',
  copyFailed = 'copyFailed',
  createTicket = 'createTicket',
  createVisitErrorOtherUserVisit = 'createVisitErrorOtherUserVisit',
  createVisitErrorUnitOccupied = 'createVisitErrorUnitOccupied',
  createVisitErrorUserHasVisit = 'createVisitErrorUserHasVisit',
  created = 'created',
  day = 'day',
  dayOff = 'dayOff',
  deep = 'deep',
  deepCleanDue = 'deepCleanDue',
  deepCleanSkipSuccess = 'deepCleanSkipSuccess',
  description = 'description',
  due = 'due',
  editType = 'editType',
  gateCode = 'gateCode',
  inProgress = 'inProgress',
  inspection = 'inspection',
  inspectionNotComplete = 'inspectionNotComplete',
  invalidImage = 'invalidImage',
  items = 'items',
  lastCompleted = 'lastCompleted',
  letUsKnowCreateATicket = 'letUsKnowCreateATicket',
  loading = 'loading',
  lockbox = 'lockbox',
  logout = 'logout',
  midStay = 'midStay',
  moreDetailsOnHome = 'moreDetailsOnHome',
  network = 'network',
  noActivity = 'noActivity',
  noComments = 'noComments',
  noPhoto = 'noPhoto',
  noResults = 'noResults',
  notYet = 'notYet',
  notes = 'notes',
  off = 'off',
  oopsChangesNotSaved = 'oopsChangesNotSaved',
  other = 'other',
  overdueTooltipText = 'overdueTooltipText',
  owner = 'owner',
  ownerArrival = 'ownerArrival',
  pageNotFound = 'pageNotFound',
  password = 'password',
  postStay = 'postStay',
  requestedBy = 'requestedBy',
  reservation = 'reservation',
  reviewDate = 'reviewDate',
  reviewOverallRating = 'reviewOverallRating',
  routine = 'routine',
  save = 'save',
  schedule = 'schedule',
  searchUnitNameOrCode = 'searchUnitNameOrCode',
  seeAllTicketsForUnit = 'seeAllTicketsForUnit',
  seeAnIssue = 'seeAnIssue',
  severity = 'severity',
  severityLow = 'severityLow',
  severityMedium = 'severityMedium',
  severityPlanned = 'severityPlanned',
  severityPriorToNextGuest = 'severityPriorToNextGuest',
  severityToday = 'severityToday',
  severityUrgent = 'severityUrgent',
  start = 'start',
  tickets = 'tickets',
  today = 'today',
  tooBusy = 'tooBusy',
  toolTipB2b = 'toolTipB2b',
  toolTipEarly = 'toolTipEarly',
  toolTipLate = 'toolTipLate',
  toolTipPotentialB2b = 'toolTipPotentialB2b',
  typeToSearch = 'typeToSearch',
  unassigned = 'unassigned',
  unit = 'unit',
  unitInfo = 'unitInfo',
  unitIsVacant = 'unitIsVacant',
  unitSearchNoResults = 'unitSearchNoResults',
  unknown = 'unknown',
  update = 'update',
  viewHome = 'viewHome',
  viewOpenTickets = 'viewOpenTickets',
  viewReservation = 'viewReservation',
  viewTicket = 'viewTicket',
  visit = 'visit',
  visits = 'visits',
  wifi = 'wifi',
}

export default Slugs
