//eslint-disable-next-line
import { isSameDay, parseISO, isBefore, startOfDay } from 'date-fns/fp'
import { filter, map, pipe, prop, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { createDateObject } from 'packages/utils/dateHelpers'

import { ApplicationState } from '../../store'
import { hydrateRawVisit } from '../visits.utils'

const filterVisitsByDay = (date: Date) =>
  pipe(prop('attributes.effectiveDate'), parseISO, isSameDay(date))

const filterActiveVisits = pipe(prop('attributes'), attrs => {
  const today = startOfDay(createDateObject())
  const isNotCompleted = attrs.completedAt === null
  const isStarted = attrs.startedAt !== null
  const isBeforeToday = isBefore(today)(
    startOfDay(parseISO(attrs.effectiveDate)),
  )

  return isNotCompleted && isBeforeToday && isStarted
})

export const getVisitsOnDate = createSelector(
  (state: ApplicationState): ApplicationState['visits'] => state.visits,
  (_, date: Date) => date,
  (state, date) =>
    pipe(
      prop('data'),
      values,
      filter(filterVisitsByDay(date)),
      map(hydrateRawVisit),
    )(state),
)

export const getActiveVisits = createSelector(
  (state: ApplicationState): ApplicationState['visits'] => state.visits,
  state =>
    pipe(
      prop('data'),
      values,
      filter(filterActiveVisits),
      map(hydrateRawVisit),
    )(state),
)
