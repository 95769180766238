import styled from '@emotion/styled'
import classNames from 'classnames'
import * as React from 'react'

import {
  DropdownButton,
  DropdownCaret,
  DropdownMenuProps,
} from 'packages/common'
import { daysFromFreezeWindow } from 'packages/grimoire'
import { IconName, SvgIcon } from 'packages/iconic'
import { colors } from 'packages/styles'
import {
  DateFormat,
  formatLocalized,
  isBefore,
  isSameDay,
  startOfDay,
  startOfToday,
  addDays,
} from 'packages/utils/dateHelpers'

import {
  CreateVisitDrawer,
  LastFetchBanner,
} from 'app/fieldapp/components/core'
import { Slugs, useI18n } from 'app/fieldapp/i18n'
import { BucketedCleans } from 'app/fieldapp/store/cleans'
import { Visit } from 'app/fieldapp/store/visits'

import { TimelineDay } from '../TimelineDay'
import { CleanTimelinePassThroughProps } from './CleanTimeline.container'

import styles from './CleansTimeline.module.scss'

const EMPTY_ARR = []

const StyledMenuItem = styled.span<{ active: boolean }>`
  color: ${colors.lake};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
`

const CustomCaret = styled(DropdownCaret)`
  border-left: 6px solid ${colors.lake};
  margin-left: 16px;
`

const St = {
  ActiveVisitsContainer: styled.div`
    padding: 0px 16px;
    cursor: pointer;
    margin-bottom: 8px;
  `,

  ActiveVisitsHeader: styled.div`
    display: flex;
    align-items: center;
    padding: 12px 16px;
    cursor: pointer;
    margin-bottom: 8px;
  `,

  ActiveVisitsTitle: styled.span`
    color: ${colors.lake};
    font-weight: bold;
    margin-right: 8px;
  `,

  Calendar: styled(SvgIcon)`
    color: ${colors.lake};
  `,

  CollapseIcon: styled(SvgIcon)`
    color: ${colors.lake};
    transition: transform 0.2s;
    &.collapsed {
      transform: rotate(-90deg);
    }
  `,

  ViewPickerContainer: styled.div`
    align-items: center;
    display: flex;
    grid-gap: 4px;
  `,
}

const TODAY = startOfToday()

export enum CleansTimelineTestIds {
  activeVisitsContainer = 'CleansTimeline__activeVisitsContainer',
  activeVisitsHeader = 'CleansTimeline__activeVisitsHeader',
  container = 'CleansTimeline__container',
  select = 'CleansTimeline__select',
}

export type FieldAppScheduleView = 'all' | 'day'

export type CleansTimelineProps = {
  activeVisits: Visit[]
  cleanBuckets: BucketedCleans
  lastFetchTimestamp: number
  setView: (view: FieldAppScheduleView) => void
  showDatePickerModal: () => void
  view: FieldAppScheduleView
  visitsOnNextDay: Visit[]
  visitsOnToday: Visit[]
} & CleanTimelinePassThroughProps

export const CleansTimeline: React.FC<CleansTimelineProps> = React.memo(
  ({
    cleanBuckets,
    lastFetchTimestamp,
    onCleanClick,
    setView,
    showDatePickerModal,
    view,
    visitsOnToday,
    visitsOnNextDay,
    activeVisits,
  }) => {
    const { t } = useI18n()
    const [showActiveVisits, setShowActiveVisits] = React.useState(false)

    const sortedMonthKeys = Object.keys(cleanBuckets).sort()

    const menuItems = React.useMemo(() => {
      return [
        {
          children: (
            <StyledMenuItem active={view === 'all'}>
              {t(Slugs.all)}
            </StyledMenuItem>
          ),
          key: 'all',
          onClick: e => setView(e.key),
        },
        {
          children: (
            <StyledMenuItem active={view === 'day'}>
              {t(Slugs.day)}
            </StyledMenuItem>
          ),
          key: 'day',
          onClick: e => setView(e.key),
        },
      ]
    }, [setView, t, view])

    const dropdownMenuProps: Omit<DropdownMenuProps, 'children'> =
      React.useMemo(() => {
        return {
          hideCaret: true,
          items: menuItems,
        }
      }, [menuItems])

    const buttonText = t(Slugs[view])

    return (
      <div
        className={styles.cleansTimeline}
        data-testid={CleansTimelineTestIds.container}
      >
        {sortedMonthKeys.map(monthKey => {
          const monthBucket = cleanBuckets[monthKey] || {}
          const sortedDayKeys = Object.keys(monthBucket).sort()

          return (
            <React.Fragment key={monthKey}>
              <div
                className={styles.monthHeader}
                data-testid={CleansTimelineTestIds.select}
              >
                <div>
                  <St.ViewPickerContainer>
                    View
                    <DropdownButton
                      dropdownMenuProps={dropdownMenuProps}
                      buttonType="text"
                    >
                      <>
                        {buttonText}
                        <CustomCaret />
                      </>
                    </DropdownButton>
                  </St.ViewPickerContainer>
                </div>
                <div>
                  {view === 'day' && (
                    <St.Calendar
                      icon={IconName.calendar}
                      size={30}
                      onClick={showDatePickerModal}
                    />
                  )}
                </div>
                <CreateVisitDrawer />
              </div>
              <>
                <div className={styles.lastFetchSpacer} />
                <LastFetchBanner
                  className={styles.lastFetchHeader}
                  lastFetch={lastFetchTimestamp}
                />
              </>
              {activeVisits.length > 0 && (
                <>
                  <St.ActiveVisitsHeader
                    onClick={() => setShowActiveVisits(!showActiveVisits)}
                    data-testid={CleansTimelineTestIds.activeVisitsHeader}
                  >
                    <St.ActiveVisitsTitle>
                      {t(Slugs.inProgress)} ({activeVisits.length})
                    </St.ActiveVisitsTitle>
                    <St.CollapseIcon
                      icon={IconName.chevronDown}
                      size={24}
                      className={classNames({ collapsed: !showActiveVisits })}
                    />
                  </St.ActiveVisitsHeader>

                  {showActiveVisits && (
                    <St.ActiveVisitsContainer
                      data-testid={CleansTimelineTestIds.activeVisitsContainer}
                    >
                      <TimelineDay
                        cleans={[]}
                        isStartOfSection={true}
                        isToday={true}
                        onCleanClick={onCleanClick}
                        visits={activeVisits}
                      />
                    </St.ActiveVisitsContainer>
                  )}
                </>
              )}

              {sortedDayKeys.reduce((acc, dayKey, dayIdx) => {
                const dayBucket = monthBucket[dayKey] || []
                const fromFreezeWindowEnd = daysFromFreezeWindow(dayKey)
                const isFrozenEnd = fromFreezeWindowEnd === 1
                const isUnfrozenStart = fromFreezeWindowEnd === 0
                const isToday = isSameDay(startOfDay(dayKey), TODAY)
                const isNextDay = isSameDay(
                  startOfDay(dayKey),
                  addDays(TODAY, 1),
                )
                const isMonthStart = dayIdx === 0

                /**
                 * Hide the date if:
                 * 1) There are no visible days yet and
                 * 2) The day is before today and
                 * 3) The day does not contain an uncompleted clean
                 * 4) the view is not one day
                 */
                const shouldHideDate =
                  acc.length === 0 &&
                  isBefore(startOfDay(dayKey), TODAY) &&
                  !monthBucket[dayKey].find(clean => !clean.completedAt) &&
                  view !== 'day'

                if (shouldHideDate) {
                  return acc
                }

                return acc.concat(
                  <div
                    className={classNames(styles.dayBucketWrapper, {
                      [styles.freezeWindowEnd]: isFrozenEnd,
                    })}
                    id={isToday ? 'today' : `${dayKey}`}
                    key={dayKey}
                  >
                    {isUnfrozenStart && (
                      <div className={styles.freezeWindowBanner}>
                        {t(Slugs.freezeWindowBody)}
                      </div>
                    )}

                    <div className={styles.timelineNav}>
                      <div
                        className={classNames({
                          [styles.todayDayMarker]: isToday,
                          [styles.notTodayDayMarker]: !isToday,
                          [styles.startOfMonthBucket]:
                            isUnfrozenStart || isMonthStart,
                        })}
                      />

                      <div
                        className={classNames(styles.dayOfWeek, {
                          [styles.today]: isToday,
                          [styles.startOfMonthBucket]:
                            isUnfrozenStart || isMonthStart,
                        })}
                      >
                        {formatLocalized(dayKey, DateFormat.DayNameShort)}
                      </div>
                      <div
                        className={classNames(styles.dayOfMonth, {
                          [styles.today]: isToday,
                        })}
                      >
                        {formatLocalized(dayKey, DateFormat.DayOfMonth)}
                      </div>
                    </div>

                    <TimelineDay
                      cleans={dayBucket}
                      isStartOfSection={isUnfrozenStart || isMonthStart}
                      isToday={isToday}
                      onCleanClick={onCleanClick}
                      visits={
                        isToday
                          ? visitsOnToday
                          : isNextDay
                          ? visitsOnNextDay
                          : EMPTY_ARR
                      }
                    />
                  </div>,
                )
              }, [] as React.ReactNode[])}
            </React.Fragment>
          )
        })}
      </div>
    )
  },
)
