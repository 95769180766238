import styled from '@emotion/styled'
import cx from 'classnames'
import { map, omit, pipe, reduce, toPairs } from 'lodash/fp'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { Alert, Button, ExternalLink } from 'packages/common'
import { OtherTimerType } from 'packages/grimoire'
import { useI18n } from 'packages/i18n'
import { useOnlineStatus } from 'packages/utils/hooks'
import { logWarning } from 'packages/wiretap/logging'

import { sumTimers } from 'app/fieldapp/components/timers/components/SubmitTimers/helpers'
import { useCheckFailedTimers } from 'app/fieldapp/components/timers/hooks/useCheckFailedTimers/useCheckFailedTimers'
import { Slugs } from 'app/fieldapp/i18n'
import { ApplicationState } from 'app/fieldapp/store/store'
import { getOfflineTimerById } from 'app/fieldapp/store/utils/timers/selectors'
import { TimerType } from 'app/fieldapp/store/utils/timers/timers.types'

import styles from '../../SubmitTimers.module.scss'
import { SubmissionTimer, SubmitTimersFormProps } from '../../types'
import { NotesSection } from '../NotesSection'
import { NotesTimerType } from '../NotesSection/NoteSection.helpers'
import { TimerInput } from '../TimerInput'

type SubmitTimersMap = {
  [timerID: string]: Omit<SubmissionTimer, 'id'>
}

const St = {
  InspectionAlert: styled(Alert)`
    margin-bottom: 0;
  `,

  MileageLink: styled(ExternalLink)`
    font-weight: bold;
  `,
}

const buildDefaultValues = reduce((acc, timer: SubmissionTimer) => {
  const { id, notes, startedAt, stoppedAt } = timer

  acc[id] = {
    notes,
    startedAt,
    stoppedAt,
  }

  return acc
}, {})

export enum SubmitTimersFormTestId {
  backBtn = 'submitTimersForm__backBtn',
  inputSection = 'submitTimersForm__inputSection',
  offlineSaveNote = 'submitTimersForm__offlineSaveNote',
  submitBtn = 'submitTimersForm__submitBtn',
}

export type SubmitTimerProps = SubmitTimersFormProps & {
  dataTestId?: string
  showInspectionWarning?: boolean
}

export const SubmitTimersForm: React.FC<SubmitTimerProps> = ({
  dataTestId,
  dispatchAbortSubmission,
  dispatchCompleteStartedOffline,
  dispatchConfirmSubmission,
  dispatchDeleteTimer,
  displayValue,
  isExternalComment: isExternalCommentProp,
  showInspectionWarning,
  // TODO: Refactor this type to be "subTimerType" where this is "deep" | "post" | OtherTimerType
  otherTimerType = undefined,
  timers = [], // workaround: unknown issue where timers appears to be undefined
  // TODO: Refactor this to only accept top level timer types - "Ticket" | "Other" | "Task"
  timerType,
}) => {
  // NOTE: this form was originally built to support multiple timers, but now only
  // supports 1 at a time, so we can safely assume that timers[0] is "the" timer
  const timer = timers[0]
  const [isInitialMount, setIsInitialMount] = useState(true)

  React.useEffect(() => {
    if (isInitialMount) {
      setIsInitialMount(false)
      if (timers.length > 1) {
        logWarning('Multiple Submit Timers Were Passed To Submit Timers Form', {
          timerIds: timers.map(timer => timer.id),
        })
      }
    }
  }, [isInitialMount, timers])

  const { t, ut } = useI18n()
  const { checkFailedTimers } = useCheckFailedTimers(false)
  const isOnline = useOnlineStatus().isOnline()
  const [totalTime, setTotalTime] = React.useState('')
  const [timerObject, setTimerObject] = React.useState<{
    id: string
    startedAt: string
    stoppedAt: string
  }>({
    id: timer?.id,
    startedAt: timer?.startedAt,
    stoppedAt: timer?.stoppedAt,
  })
  const [isExternalComment, setIsExternalComment] = React.useState<boolean>(
    isExternalCommentProp === undefined ? false : isExternalCommentProp,
  )

  // listens for changes in timerObject, then calculates new total time
  React.useEffect(() => {
    const calculatedTotalTime = sumTimers([timerObject])
    setTotalTime(calculatedTotalTime)
  }, [timerObject])

  const handleTimerChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isStartedAt = e.target.name.endsWith('startedAt')
      const name = isStartedAt ? 'startedAt' : 'stoppedAt'
      setTimerObject(prevState => ({
        ...prevState,
        [name]: e.target.value,
      }))
    },
    [],
  )

  const offlineTimer = useSelector((state: ApplicationState) =>
    getOfflineTimerById(state, timer?.id),
  )

  const { control, errors, formState, handleSubmit, register, trigger, watch } =
    useForm({
      defaultValues: buildDefaultValues(timers),
      mode: 'onChange',
      reValidateMode: 'onChange',
    })

  const { isSubmitting, isValid } = formState

  const handleSubmitTimer = async (data: SubmitTimersMap) => {
    /** merge the id (key) of the timer submission data into the timer itself **/
    const flattenTimer = ([key, value]) => ({ id: key, ...value })

    /** re-map timer submission data from an object keyed by ID to an Array **/
    const submissionTimers: SubmissionTimer[] = pipe(
      toPairs,
      map(flattenTimer),
    )(data)

    const submittingTimer = { ...submissionTimers[0], isExternalComment }

    /** Since we can only have one timer now, we only care about the first (and only) value here */
    const timerFormData = Object.values(data)[0]
    const formTimer = {
      ...omit('startedOffline')(timerFormData),
      isExternalComment,
    }

    /** Timer should exist, but if it does not, dispatchConfirmSubmission will handle the error properly  */
    if (timer?.startedOffline) {
      /**
       * Here we combine all the data we have in the correct order we want it
       * - start with the the redux data
       * - then override with any offline data
       * - then finally, override with any changes made in the submit form
       *
       * We don't need to worry about potential null/undefined values for times in offlineTimers,
       * because we know those are required in the form data
       * */
      const fullTimer: SubmissionTimer = {
        ...timer,
        ...(offlineTimer?.attributes as SubmissionTimer),
        ...formTimer,
      }

      await dispatchCompleteStartedOffline(fullTimer)
    } else {
      await dispatchConfirmSubmission([submittingTimer])
    }

    // After a successful submit, check for pending timers.
    checkFailedTimers()
  }

  // default values validation
  React.useEffect(() => {
    trigger()
  }, [trigger])

  const isPostStayCleanTimer = timerType === 'post-stay'
  const isDeepCleanTimer = timerType === 'deep'
  const isVisitTimer = timerType === 'visit'
  const isTicketTimer = timerType === TimerType.TICKET
  const isOtherTimer = timerType === TimerType.OTHER
  const isDriveTimer = otherTimerType === OtherTimerType.DRIVE
  const mileageString = `${ut(Slugs.trackMileage)} `

  /** Determine timer type that "NotesSection" needs  */
  const notesTimerType: NotesTimerType =
    timerType === TimerType.TICKET ? TimerType.TICKET : otherTimerType

  /*
   * Back button must be disabled in a edge case when user is offline but
   * there is no offline timer data to "go back" to.
   */
  const isBackButtonDisabled = React.useMemo(() => {
    const isMissingOfflineTimer = !isOnline && !offlineTimer
    return isMissingOfflineTimer || isSubmitting
  }, [isOnline, isSubmitting, offlineTimer])

  return (
    <form data-testid={dataTestId} onSubmit={handleSubmit(handleSubmitTimer)}>
      <div className={styles.container}>
        {showInspectionWarning && timerType !== 'visit' && (
          <St.InspectionAlert alertType={'warning'}>
            {t(Slugs.inspectionNotComplete)}
          </St.InspectionAlert>
        )}

        <div
          className={cx(styles.header, {
            [styles.postStayCleanTimer]: isPostStayCleanTimer,
            [styles.deepCleanTimer]: isDeepCleanTimer,
            [styles.ticketTimer]: isTicketTimer,
            [styles.visitTimer]: isVisitTimer,
          })}
        >
          <div
            className={cx(styles.headerText, {
              [styles.otherTimer]: isOtherTimer,
            })}
          >
            <div className={styles.headerLabel}>{t(Slugs.submitHours)}</div>
            <div className={styles.cleanInfo}>
              <div className={styles.unitInfo}>{displayValue}</div>
              <div>{totalTime}</div>
            </div>
          </div>
        </div>
        <div className={styles.timerDisplaySection}>
          {timers.map((timer: SubmissionTimer) => (
            <TimerInput
              control={control}
              errors={errors[timer.id]}
              key={timer.id}
              onTimerChange={handleTimerChange}
              onDelete={() => dispatchDeleteTimer(timer.id)}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              trigger={trigger as any}
              watch={watch}
              {...timer}
            />
          ))}
        </div>

        <div
          data-testid={SubmitTimersFormTestId.inputSection}
          className={styles.textInputs}
        >
          <NotesSection
            register={register}
            timerType={notesTimerType}
            timer={timer}
            isExternalComment={isExternalComment}
            setIsExternalComment={setIsExternalComment}
          />
          {isDriveTimer && (
            <div className={styles.mileageNotice}>
              {mileageString}
              <St.MileageLink text="Coupa" url="https://vacasa.coupahost.com" />
            </div>
          )}
        </div>

        <div className={styles.buttonsSection}>
          <div className={styles.buttons}>
            <Button
              dataTestId={SubmitTimersFormTestId.backBtn}
              className={styles.button}
              disabled={isBackButtonDisabled}
              onClick={dispatchAbortSubmission}
              buttonType={'utility'}
            >
              {ut(Slugs.back)}
            </Button>

            <Button
              dataTestId={SubmitTimersFormTestId.submitBtn}
              className={styles.button}
              disabled={!isValid}
              isLoading={isSubmitting}
              buttonType={'primary'}
              isFormSubmit={true}
            >
              {isOnline ? ut(Slugs.submit) : ut(Slugs.save)}
            </Button>
          </div>
        </div>

        {!isOnline && (
          <div
            className={styles.offlineSaveNote}
            data-testid={SubmitTimersFormTestId.offlineSaveNote}
          >
            {t(Slugs.offlineTimersSubmitNote)}
          </div>
        )}
      </div>
    </form>
  )
}
