import { createAsyncThunk } from '@reduxjs/toolkit'

import { fetchPrimaryDataBatch } from './fetchPrimaryDataBatch'
import { fetchSecondaryDataBatch } from './fetchSecondaryDataBatch'

export const fanOutDataBatchRequests = createAsyncThunk<
  void,
  boolean | undefined
>(
  'app/fanOutDataBatchRequests',
  async (fetchActiveVisits = false, { dispatch }) => {
    await dispatch(fetchPrimaryDataBatch(fetchActiveVisits))
    await dispatch(fetchSecondaryDataBatch())
  },
)
